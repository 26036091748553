import AdminMenu from 'components/AdminMenu';
import styled from 'styled-components';
import axios from 'axios';
import {useState, useEffect} from 'react';
import {Link, useParams,useNavigate} from 'react-router-dom';
import PagingBox from 'components/PagingBox';


function PaymentWrite() {

    return (
        <div className="rootConatiner">
           
            <ContentArticle>
                

                <section class="boardArti boardWriteArti">
                    <div class="inner">
                        <div class="boardBanner">
                            <div class="t1">Payment</div>
                            <div class="t2">개인결제</div>
                        </div>
                        
                        
                        <article class="infoBoxWrap">
                            <ul class="infoBoxList">
                                <li class="full">
                                    <div class="infoBox">
                                        <div class="label">분류</div>
                                        <div class="txtBox">
                                            <select class="ipt">
                                                <option value="">분류를 선택하세요</option>
                                                <option value="">분류를 선택하세요</option>
                                                <option value="">분류를 선택하세요</option>
                                            </select>
                                        </div>
                                    </div>
                                </li>
                                <li class="half">
                                    <div class="infoBox">
                                        <div class="label">이름</div>
                                        <div class="txtBox"><input type="text" name="" value="" placeholder="이름" class="ipt" /></div>
                                    </div>
                                </li>
                                <li class="half">
                                    <div class="infoBox">
                                        <div class="label">비밀번호</div>
                                        <div class="txtBox"><input type="password" name="" value="" placeholder="이름" class="ipt" /></div>
                                    </div>
                                </li>
                                <li class="half">
                                    <div class="infoBox">
                                        <div class="label">이메일</div>
                                        <div class="txtBox"><input type="email" name="" value="" placeholder="이메일" class="ipt" /></div>
                                    </div>
                                </li>
                                <li class="half">
                                    <div class="infoBox">
                                        <div class="label">홈페이지</div>
                                        <div class="txtBox"><input type="homepage" name="" value="" placeholder="홈페이지" class="ipt" /></div>
                                    </div>
                                </li>
                                <li class="full">
                                    <div class="infoBox">
                                        <div class="label">설정</div>
                                        <div class="txtBox">
                                            <select class="ipt">
                                                <option value="">분류를 선택하세요</option>
                                                <option value="">분류를 선택하세요</option>
                                                <option value="">분류를 선택하세요</option>
                                            </select>
                                        </div>
                                    </div>
                                </li>
                                <li class="full">
                                    <div class="infoBox">
                                        <div class="label">제목</div>
                                        <div class="txtBox">
                                            <input type="text" name="" value="" placeholder="제목" class="ipt" />
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </article>

                        <article class="dhtmlBox">
                            dhtml을 넣어주세요.
                        </article>

                        <article class="addArti">
                            <div class="addWrap">
                                <div class="tag"># 링크 1</div>
                                <input type="url" class="ipt link" placeholder="링크를 입력해주세요" />
                            </div>
                            <div class="addWrap">
                                <div class="tag"># 링크 2</div>
                                <input type="url" class="ipt link" placeholder="링크를 입력해주세요" />
                            </div>

                            <div class="addWrap">
                                <div class="tag"># 파일 1</div>
                                <input type="file" class="ipt file" placeholder="링크를 입력해주세요" />
                                <label class="delBox">
                                    <input type="checkbox"/>
                                    <p>file.jpg(11.4K) 파일삭제</p>
                                </label>
                            </div>
                            <div class="addWrap">
                                <div class="tag"># 파일 2</div>
                                <input type="file" class="ipt file" placeholder="링크를 입력해주세요" />
                                <label class="delBox">
                                    <input type="checkbox"/>
                                    <p>file.jpg(11.4K) 파일삭제</p>
                                </label>
                            </div>

                        </article>

                        <article class="btnWrap">
                            <a href="./basicList.html" class="cancel boardBtn">취소</a>
                            <button type="button" class="submit boardBtn">작성완료</button>
                        </article>
                        
                    </div>
                </section>




            </ContentArticle>
        </div>
    )
}

const ListWrap = styled.article`
    margin-top:40px;
`

const ContentArticle = styled.article`
    .inner{ width: 100%; padding:0 10px; }
    .boardArti{ padding:50px 0 100px 0; }
    .subContent { margin:30px 0; font-size:20px; }
    .formList.type2{ margin-top:40px; }
    .btnWrap{ margin-top:30px; display:flex; align-items:center; justify-content:center; }
    .btnWrap .submitBtn{ border:none; width:100%; max-width:310px; border-radius:60px; padding:22px 10px; background-color:#222; color:#fff; font-size:18px; font-weight:700; color:#fff; }


    @media screen and (max-width: 1250px) {
        .subContent { font-size:18px; }
    }
    @media screen and (max-width: 650px) {
        .boardArti{ padding:20px 0 100px 0; }
        .subContent { font-size:16px; margin:10px 0; }
        .btnWrap{ margin-top:15px; }
        .btnWrap .submitBtn{ padding:15px 10px; font-size:16px; }

    }
`
const BottomBarTitle = styled.article`
    display:flex; align-items:center; gap:10px; border-bottom:4px solid #222; padding-bottom:13px;
    .title{ font-size:26px; font-weight:700; }

    @media screen and (max-width: 1250px) {
        gap:5px;
        .title{ font-size:20px; }
        .logo{ width:30px; }
    }
    @media screen and (max-width: 650px) {
        border-bottom:2px solid #222; 
        .title{ font-size:18px; }
        .logo{ width:25px; }
    }
`

const BannerArti = styled.article `
    padding: 200px 0 100px 0; background-size: cover; background-repeat: no-repeat; background-position: center;
    background-image : ${ (
    props
) => props.bgImage
    ? `url(${props.bgImage})`
    : `url(${process.env.PUBLIC_URL}/images/example_img.png)`};
    color:#FFF; 

    .title{ font-size:56px; font-weight:700; }
    .content{ font-size:18px; margin-top:20px; }
    .inner{ display:flex; justify-content: space-between; gap:20px; flex-wrap:wrap; }
    .contentBox{ line-height:1.5em; font-size:26px; font-weight:700; }


    @media screen and (max-width: 1250px) {
        .title{ font-size:40px; }
        .content{ font-size:16px; }
        .contentBox{ width:100%; font-size:18px; }
    }
    @media screen and (max-width: 650px) {
        .title{ font-size:35px; }
        .content{ font-size:14px; margin-top:10px; }
        .contentBox{ font-size:16px; }
    }
`;

export default PaymentWrite;
