import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components'
import "./Footer.css"


const Footer = ({setPrivacyPopup, privacyPopup}) => {

    const {pathname} = useLocation();

    const privacyPopupHandler = (e) => {
        e.preventDefault();
        setPrivacyPopup(!privacyPopup);
    }
    if(!pathname.startsWith('/mypage')){
        return (
            <>
                <PrivacyPopup active={privacyPopup} onClick={privacyPopupHandler}>
                    <div className="popupContainer">
                        <div className="btnWrap">
                            <div className="btn">
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                        <div className="contentBox" onClick={(e) => {e.stopPropagation();}}>
                            <div className="title">개인정보 처리방침</div>
                            <div className="content">
                                JOB OF BROTHERS(이하 "회사"라 합니다)는 『개인정보 보호법』,<br/>
                                『정보통신망 이용촉진 및 정보보호 등에 관한 법률』등 준수하여야 할 관련 법규상의 개인정보 보호규정을 준수하며,<br/>
                                관련 법규에 의거한 개인정보 처리방침을 정하여 이용자 권익 보호에 최선을 다하고 있습니다.<br/>
                                이 개인정보 처리방침의 "회원"이란 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』제2조 제4호의 정보통신 서비스 제공자가 제공하는 정보통신 서비스 이용자에 한정합니다.<br/>
                                회사는 회원의 개인정보를 수집 이용하고자 하는 경우에는 『개인정보 보호법』 제15조, 제22조 및 제24조에 따라 회원님 본인의 동의를 얻어야 합니다.<br/>
                                또한, 회원으로부터 취득한 개인정보는 『개인정보보호법』제17조 및 제22조, 제24조에 따라 제3자에게 제공할 경우 회원님 본인의 사전 동의를 얻어야 합니다.<br/>
                                이 개인정보 처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행 7일 이전부터 공지사항을 통하여 고지할 것입니다.<br/>
                                <br/>
                                1. 개인정보 수집 이용 목적<br/>
                                1) 홈페이지 회원가입 및 관리<br/>
                                회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별 인증, 회원자격 유지 관리, 제한적 본인 확인제 시행에 따른 본인 확인, 서비스 부정이용 방지, 만14세 미만 아동 개인정보 수집 시 법정대리인 동의 여부 확인, 각종 고지·통지, 고충처리, 분쟁 조정을 위한 기록 보존 등<br/>
                                2) 민원업무 처리<br/>
                                민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락·통지, 처리결과 통보 등<br/>
                                3) 재화 또는 서비스 제공 서비스 개발 및 개선, 컨텐츠 제공, 청구서 발송, 금융거래 본인인증 및 금융서비스, 연령인증, 요금결제·정산, 연체자 관리 및 채무 불이행시 위험관리, 채권추심, 대출/투자 서비스 제공 등<br/>
                                4) 마케팅 및 광고에의 활용<br/>
                                신규 서비스 개발과 이벤트 행사에 따른 최신정보 전달 및 맞춤 서비스 제공, 통계학적 분석에 따른 서비스 제공 및 광고 게재 등<br/>
                                <br/>
                                2. 수집 이용할 개인정보의 내용<br/>
                                회사는 회원 가입, 원활한 고객 상담, 각종 서비스 등 기본적인 서비스 제공을 위한 필수 정보와 고객 맞춤 서비스 제공을 위하여 아래와 같이 개인정보를 수집하고 있습니다.<br/>
                                1) 개인 식별 정보<br/>
                                성명, 생년월일, 성별, 사업자번호, 주민등록증발급일, 연락처(휴대전화, 자택, 직장), 주소(자택, 직장), 이메일, 직장명, 부서, 직위, 국적, 운전면허번호, 여권번호, 외국인등록번호, ID, 비밀번호, 접속로그, 쿠키, 서비스 이용 기록, 접속 IP 정보, 음성 DATA 등<br/>
                                2) 투자 서비스 이용 시<br/>
                                은행계좌정보, 주민(법인)등록번호(투자수익에 대한 원천징수신고서용)<br/>
                                <br/>
                                3. 개인정보의 보유 및 이용 기간<br/>
                                회사는 정보주체로부터 개인정보를 수집 시에 동의 받은 개인정보를 회원 탈퇴후 또는 채권 채무관계 종료일로부터 5년(단, 관련 법령의 별도의 규정이 명시되어 있는 경우 그 기간을 따름)동안 보유합니다.<br/>
                                <br/>
                                4. 개인정보의 파기 절차 및 방법<br/>
                                회사는 원칙적으로 개인정보 수집 및 이용 목적이 달성되거나 보유 및 이용 기간이 종료한 경우 해당정보를 지체 없이 파기합니다. 파기 절차, 기한 및 방법은 다음과 같습니다.<br/>
                                <br/>
                                1) 파기 절차 : 이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의 데이터베이스 시스템(DB)으로 옮겨져 (종이의 경우 별도의 서류함) 내부방침 및 기타 관련법령에 의한 정보보호 사유에 따라 (보유 및 이용 기간 참조) 일정기간 저장된 후 파기됩니다.<br/>
                                2) 파기 기한 : 이용자의 개인정보는 개인정보의 보유기간이 경과된 경우에는 보유기간의 종료일로부터 5일 이내에, 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 5일 이내에 그 개인정보를 파기합니다.<br/>
                                3) 파기 방법 : 별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다. 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.<br/>
                                <br/>
                                5. 정보주체의 권리, 의무 및 그 행사방법<br/>
                                1) 개인정보의 열람 정정<br/>
                                회원 및 법정대리인은 언제든지 등록되어 있는 고객의 개인정보를 열람하거나 정정할 수 있습니다.<br/>
                                개인정보 열람 및 정정은 "회원정보 수정"에서 직접 처리할 수 있으며, 개인정보 보호법 시행규칙 별지 제8호 서식에 따라 서면, 전자우편, FAX 등을 통해서도 가능합니다.<br/>
                                2) 개인정보의 삭제 요구<br/>
                                정보 제공 주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 회사는 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다.<br/>
                                3) 개인정보의 수집·이용·제공 동의철회<br/>
                                회원은 언제든지 개인정보의 수집 이용 제공에 대한 동의내용을 철회할 수 있습니다. 동의 철회는 개인정보 보호법 시행규칙 별지 제8호 서식에 따라 서면, 전자우편, FAX 등을 통해서 신청할 수 있으며, 회사는 접수 즉시 동의 철회 등록, 개인정보 파기 등의 조치를 취한 후 해당사실을 고객에게 통지합니다.<br/>
                                다만, 회사는 이용자 혹은 법정 대리인의 요청에 의해 동의철회, 파기된 개인정보는 "개인정보 보유 및 이용기간"에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.<br/>
                                <br/>
                                6. 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항<br/>
                                회사는 고객의 정보를 수시로 저장하고 불러오는 "쿠키(cookie)"를 운용합니다. 쿠키란 회사의 웹사이트를 운영하는데 이용되는 서버가 이용자의 컴퓨터 브라우저에 보내는 아주 작은 텍스트 파일이며 이용자의 컴퓨터 하드디스크에 저장되기도 합니다.<br/>
                                1) 쿠키 등 사용 목적<br/>
                                회원과 비회원의 접속빈도나 방문시간 등을 분석, 이용자의 취향과 관심분야를 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문회수 파악 등을 통한 타겟 마케팅 및 개인 맞춤 서비스 제공 등을 위하여 사용될 수 있습니다.<br/>
                                2) 쿠키의 설치 운영 및 거부<br/>
                                회원은 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 회원은 웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다. 단, 회원이 쿠키 설치를 거부하였을 경우 서비스 제공에 어려움이 있을 수 있습니다. ☞ 설정 방법 : 웹 브라우저 상단의 도구 → 인터넷 옵션 → 개인정보<br/>
                                <br/>
                                7. 개인정보에 관한 민원서비스<br/>
                                회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.<br/>
                                1) 개인정보보호책임자<br/>
                                - 기업명 : job of brothers<br/>
                                - 직 책 : 대표<br/>
                                - 전화번호 : 010 - 5594 - 9559<br/>
                                - e-mail : dhrn0623@naver.com<br/>
                                2) 민원 처리<br/>
                                회사의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있으며, 회사는 고객의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.<br/>
                                3) 기타 신고 또는 상담센터<br/>
                                - 개인정보분쟁조정위원회 (www.1336.or.kr ☏ 1336)<br/>
                                - 정보보호마크인증위원회 (www.eprivacy.or.kr ☏ 02-580-0533~4)<br/>
                                - 대검찰청 인터넷범죄수사센터 (http://icic.sppo.go.kr ☏ 02-3480-3600)<br/>
                                - 경찰청 사이버안전국 (www.ctrc.go.kr ☏ 02-392-0330)<br/>
                                <br/>
                                8. 개인정보의 안전성 확보 조치<br/>
                                회사는 『개인정보보호법』제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적 관리적 및 물리적 조치를 하고 있습니다.<br/>
                                1) 개인정보 취급 직원의 최소화 및 교육<br/>
                                개인정보를 취급하는 직원을 지정하고 업무를 담당자에 한정시키고 관리 직원을 최소화하여 개인정보를 관리를 시행하고 있습니다.<br/>
                                2) 내부관리계획의 수립 및 시행<br/>
                                개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.<br/>
                                3) 특명 감사 실시 개인정보 취급 관련 안정성 확보를 위해 정기적(월 1회)으로 자체 특명 감사를 실시하고 있습니다.<br/>
                                4) 개인정보의 암호화<br/>
                                이용자의 개인정보는 비밀번호는 암호화되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.<br/>
                                5) 해킹 등에 대비한 기술적 대책<br/>
                                회사는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적 물리적으로 감시 및 차단하고 있습니다.<br/>
                                6) 개인정보에 대한 접근 제한<br/>
                                개인정보를 처리하는 데이터베이스 시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며, 침입차단 시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.<br/>
                                7) 접속기록의 보관 및 위변조 방지<br/>
                                개인정보처리 시스템에 접속한 기록을 최소 6개월 이상 보관, 관리하고 있으며, 접속 기록이 위 변조 및 도난, 분실되지 않도록 보안기능 사용하고 있습니다.<br/>
                                8) 문서보안을 위한 잠금장치 사용<br/>
                                개인정보가 포함된 서류, 보조저장매체 등은 잠금장치가 있는 안전한 장소에 보관하고 있습니다.<br/>
                                9) 비인가자에 대한 출입 통제<br/>
                                개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립 운영하고 있습니다.<br/>
                            </div>
                        </div>
                    </div>
                </PrivacyPopup>
                
                <footer id="footer_24">
                    <div className="inner">
                        <div className="logoDiv">
                            <img src={`${process.env.PUBLIC_URL}/images/2024/common/footer_logo.png`} alt="JOBOFBROTHERS" className="logo" />
                            <ul className="menuList">
                                <li><a href="#" onClick={privacyPopupHandler} >개인정보처리방침</a></li>
                                <li><Link to="/contact/write">문의하기</Link></li>
                                <li><a href="https://blog.naver.com/jobofbrothers" target="_blank" rel="noreferrer">블로그</a></li>
                            </ul>
                        </div>
                        <div className="infoDiv">
                            <div className="leftBox">
                                <div className="title">Business Info</div>
                                <ul className="info_list">
                                    <li>서울특별시 금천구 가산디지털2로 173, 11층 1117호</li>
                                    <li>사업자 등록번호 : 457-15-01816</li>
                                    <li>E-mail : jobofbrothers@naver.com</li>
                                </ul>
                            </div>
                            <div className="rightBox">
                                <a href="https://pf.kakao.com/_RCxoxib" target="_blank" className="kakao">
                                    <img  src={`${process.env.PUBLIC_URL}/images/2024/common/kakao_icon.png`} alt="카카오톡 채널" className="icon" />
                                    카카오톡 채널 ‘JobofBrothers’
                                </a>
                                <div className="halfBox">
                                    <div className="box">고객센터 0507-1409-9559</div>
                                    <div className="box">
                                        [평일] 오전 9시 ~ 오후 6시<br/>
                                        [점심시간] 오후 12시 ~ 1시
                                    </div>
                                </div>
                                <div className="copy_right">© JOB Of Brothers All rights reserved.</div>
                            </div>
                        </div>
                    </div>
                </footer>
            </>
            
        );
    }
}



const PrivacyPopup = styled.div`
    position: fixed; z-index:999; background-color: rgba(0,0,0,0.5);
    left:0; top:0; width:100%; height:100%; padding: 10px;
    display:flex; justify-content: center; align-items: center;
    opacity:${(props)=> props.active ? "1": "0"}; 
    visibility: ${(props) => props.active ? 'visible':'hidden'}; 
    transition:opacity 0.5s, visibility 0.5s;
    
    .popupContainer{ 
        max-width:600px; 
        .btnWrap{
            display:flex; justify-content: flex-end; align-items: center;
            .btn{ 
                width: 40px; height:40px; 
                cursor: pointer;
                >span{ 
                    display:block; width:100%; height:4px; background-color:#fff; margin-top:10px; 
                    &:nth-child(1){ transform: translateY(7px) rotate(45deg); }
                    &:nth-child(2){ transform: translateY(-7px) rotate(-45deg); }
                }
            }
        }
        .contentBox{ 
            background-color:#fff; padding:40px 20px; max-height:80vh; overflow-y:scroll;  
            .title{ text-align:center; font-size:30px; font-weight:600;  }
            .content{ margin-top:40px; }
            @media screen and (max-width: 650px) {
                .title{ font-size:20px;  }
                .content{ font-size:14px; }
            }
        }
        
    }

`;

export default Footer;