import AdminMenu from 'components/AdminMenu';
import styled from 'styled-components';
import axios from 'axios';
import {useState, useEffect} from 'react';
import {Link, useParams,useNavigate} from 'react-router-dom';
import PagingBox from 'components/PagingBox';


function MaintenanceWrite() {

    return (
        <div className="rootConatiner">
            <BannerArti bgImage={`${process.env.PUBLIC_URL}/images/2024/maintenance/bannerBg.png`}>
                <div className='inner'>
                    <div className='textBox'>
                        <p className="title">Maintenance</p>
                    </div>
                    <div class="contentBox">
                        친절하고 자세하게<br/>
                        안내드리겠습니다
                    </div>
                </div>
            </BannerArti>
            <ContentArticle>
                
            <ListWrap>
                <article class="boardArti basicBoardArti">
                    <div class="inner">
                        <BottomBarTitle>
                            <img src={`${process.env.PUBLIC_URL}/images/2024/maintenance/logo.png`} alt="잡오브브라더스" className="logo"/>
                            <div className="title">유지보수 신청하기</div>
                        </BottomBarTitle>
                        <div className="subContent">
                            사이트를 수정하실 내용을 입력해주세요.
                        </div>

                        <ul className="formList">
                            <li className="half">
                                <div className="label">회사명 <span className="red">*</span></div>
                                <div className="iptBox">
                                    <input type="text" class="ipt" placeholder="" />
                                </div>
                            </li>
                            <li className="half">
                                <div className="label">이메일 <span className="red">*</span></div>
                                <div className="iptBox">
                                    <input type="text" class="ipt" placeholder="" />
                                </div>
                            </li>
                            <li className="half">
                                <div className="label">연락처 <span className="red">*</span></div>
                                <div className="iptBox">
                                    <input type="text" class="ipt" placeholder="" />
                                </div>
                            </li>
                            <li className="half">
                                <div className="label">신청구분 <span className="red">*</span></div>
                                <div className="iptBox">
                                    <select class="ipt">
                                        <option value="">신청구분을 선택해주세요</option>
                                    </select>
                                </div>
                            </li>
                            <li className="half">
                                <div className="label">작성글 비밀번호 <span className="red">*</span></div>
                                <div className="iptBox">
                                    <input type="text" class="ipt" placeholder="" />
                                </div>
                            </li>
                            <li>
                                <div className="label">제목 <span className="red">*</span></div>
                                <div className="iptBox">
                                    <input type="text" class="ipt" placeholder="" />
                                </div>
                            </li>
                            <li>
                                <div className="label">상세내용 <span className="red">*</span></div>
                                <div className="iptBox">
                                    <textarea class="ipt"></textarea>
                                </div>
                            </li>
                        </ul>
                        <div class="agreeDiv agreeCheckDiv">
                            <label>
                                <input type="checkbox" id="mailCheckbox" />
                                <div class="textBox"><span class="loginblue"> 개인정보처리방침에 동의합니다.</span></div>
                            </label>
                        </div>

                        <ul className="formList type2">
                            <li>
                                <div className="label">회사명 <span className="red">*</span></div>
                                <div className="iptBox">
                                    <input type="file" class="ipt" placeholder="" />
                                </div>
                            </li>
                        </ul>

                        <div className="btnWrap">
                            <button type="submit" className="submitBtn">신청하기</button>
                        </div>
                    </div>
                </article>
            </ListWrap>
            </ContentArticle>
        </div>
    )
}

const ListWrap = styled.article`
    margin-top:40px;
`

const ContentArticle = styled.article`
    .inner{ width: 100%; padding:0 10px; max-width:800px; }
    .boardArti{ padding:50px 0 100px 0; }
    .subContent { margin:30px 0; font-size:20px; }
    .formList.type2{ margin-top:40px; }
    .btnWrap{ margin-top:30px; display:flex; align-items:center; justify-content:center; }
    .btnWrap .submitBtn{ border:none; width:100%; max-width:310px; border-radius:60px; padding:22px 10px; background-color:#222; color:#fff; font-size:18px; font-weight:700; color:#fff; }


    @media screen and (max-width: 1250px) {
        .subContent { font-size:18px; }
    }
    @media screen and (max-width: 650px) {
        .boardArti{ padding:20px 0 100px 0; }
        .subContent { font-size:16px; margin:10px 0; }
        .btnWrap{ margin-top:15px; }
        .btnWrap .submitBtn{ padding:15px 10px; font-size:16px; }

    }
`
const BottomBarTitle = styled.article`
    display:flex; align-items:center; gap:10px; border-bottom:4px solid #222; padding-bottom:13px;
    .title{ font-size:26px; font-weight:700; }

    @media screen and (max-width: 1250px) {
        gap:5px;
        .title{ font-size:20px; }
        .logo{ width:30px; }
    }
    @media screen and (max-width: 650px) {
        border-bottom:2px solid #222; 
        .title{ font-size:18px; }
        .logo{ width:25px; }
    }
`

const BannerArti = styled.article `
    padding: 200px 0 100px 0; background-size: cover; background-repeat: no-repeat; background-position: center;
    background-image : ${ (
    props
) => props.bgImage
    ? `url(${props.bgImage})`
    : `url(${process.env.PUBLIC_URL}/images/example_img.png)`};
    color:#FFF; 

    .title{ font-size:56px; font-weight:700; }
    .content{ font-size:18px; margin-top:20px; }
    .inner{ display:flex; justify-content: space-between; gap:20px; flex-wrap:wrap; }
    .contentBox{ line-height:1.5em; font-size:26px; font-weight:700; }


    @media screen and (max-width: 1250px) {
        .title{ font-size:40px; }
        .content{ font-size:16px; }
        .contentBox{ width:100%; font-size:18px; }
    }
    @media screen and (max-width: 650px) {
        .title{ font-size:35px; }
        .content{ font-size:14px; margin-top:10px; }
        .contentBox{ font-size:16px; }
    }
`;

export default MaintenanceWrite;
