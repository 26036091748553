import AdminMenu from 'components/AdminMenu';
import styled from 'styled-components';
import axios from 'axios';
import {useState, useEffect} from 'react';
import {Link, useParams,useNavigate} from 'react-router-dom';
import PagingBox from 'components/PagingBox';


function PaymentView() {

    return (
        <div className="rootConatiner">
            <BannerArti bgImage={`${process.env.PUBLIC_URL}/images/2024/payment/bannerBg.png`}>
                <div className='inner'>
                    <div className='textBox'>
                        <p className="title">Payment</p>
                    </div>
                    <div class="contentBox">
                        친절하고 자세하게<br/>
                        안내드리겠습니다
                    </div>
                </div>
            </BannerArti>
            <ContentArticle>
                
            <ListWrap>
                <section class="boardArti boardViewArti">
                    <div class="inner">
                        <BottomBarTitle>
                            <img src={`${process.env.PUBLIC_URL}/images/2024/maintenance/logo.png`} alt="잡오브브라더스" className="logo"/>
                            <div className="title">개인결제 게시판</div>
                        </BottomBarTitle>
                        <article class="infoBoxWrap">
                            <ul class="infoBoxList">
                                <li class="full">
                                    <div class="infoBox">
                                        <div class="label">제목</div>
                                        <div class="txtBox viewBox">
                                            <div class="txt">
                                                제목 영역입니다. 홈페이지가 오픈하였습니다. 제목 영역입니다. 홈페이지가 오픈하였습니다. 제목 영역입니다. 홈페이지가 오픈하였습니다.
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="infoBox">
                                        <div class="label">이름</div>
                                        <div class="txtBox viewBox">
                                            <div class="txt">
                                                최고관리자
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="infoBox">
                                        <div class="label">조회</div>
                                        <div class="txtBox viewBox">
                                            <div class="txt">
                                                9,999
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="infoBox">
                                        <div class="label">날짜</div>
                                        <div class="txtBox viewBox">
                                            <div class="txt">
                                                20-05-18 13:22
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            
                            </ul>
                        </article>

                        <div class="menuBox">
                            <AdminMenu boardName="maintenance" type="view" />
                        </div>

                        <article class="viewContent">
                            어떤 디자이너들은 명쾌한 방법론을 갈망하는 한편, 어떤 디자이너들은 창의적인 발상을 제약하는 사고 방식이라며 발끈하기도 한다. <br/>
                            하지만, 혼란스럽거나 즉흥적인 접근 방식은 바람직하다고 이야기하기 어렵다. <br/>
                            여기서 설명하고 있는 방법론은 제약적인 프로세스라기보다는 모든 상황에서 따라 할 수 있는 조리법처럼 받아들여지기를 바란다. <br/>
                            하지만 기계적으로 따라야만 하는 방법은 없다. <br/>
                            디자이너의 창의적인 번뜩임은 전기와 같고 그 방법은 전기를 가장 잘 이용할 수 있는 곳으로 전달하는 전력망 이라고 상상해보자.
                        </article>

                        <article class="viewAddArti">
                            <a href="#">
                                <div class="item file">
                                    <p class="t1">File.jpeg(741.5K)</p>
                                    <p class="t2">1회 다운로드 | DATE : 2020-05-05- 17:05:20</p>
                                </div>
                            </a>
                            <a href="#">
                                <div class="item link">
                                    <p class="t1">http://www.link.com</p>
                                    <p class="t2">34회 연결</p>
                                </div>
                            </a>
                        </article>

                        <article class="prevNextArti">
                            <div class="item">
                                <div class="label">
                                    <img src={`${process.env.PUBLIC_URL}/images/2024/boardImg/chevron-up.png`} alt="이전글" />
                                    이전글
                                </div>
                                <div class="titleBox">
                                    <a href="#">
                                        이전글 제목입니다.이전글 제목입니다.이전글 제목입니다.이전글 제목입니다.이전글 제목입니다.이전글 제목입니다.이전글 제목입니다.이전글 제목입니다.
                                        이전글 제목입니다.이전글 제목입니다.이전글 제목입니다.이전글 제목입니다.이전글 제목입니다.
                                    </a>
                                </div>
                            </div>
                            <div class="item">
                                <div class="label">
                                    <img src={`${process.env.PUBLIC_URL}/images/2024/boardImg/chevron-down.png`} alt="다음글" />
                                    다음글
                                </div>
                                <div class="titleBox">
                                    <a href="#">
                                        다음글 제목입니다.다음글 제목입니다.다음글 제목입니다.다음글 제목입니다.다음글 제목입니다.다음글 제목입니다.다음글 제목입니다.다음글 제목입니다.
                                        다음글 제목입니다.다음글 제목입니다.다음글 제목입니다.다음글 제목입니다.
                                    </a>
                                </div>
                            </div>
                        </article>

                        {/* <article class="commentArti">
                            <div class="commentBtn">
                                <div class="count"> 댓글 <b>3</b></div>
                                <button type="button" class="toggleBtn">
                                    <p class="close">닫기</p>
                                    <p class="open">열기</p>
                                    <img src="./img/boardImg/chevron-up.png" alt="화살표" class="icon"/>
                                </button>
                            </div>
                            
                            <div class="commentList">
                                <div class="commentBox">
                                    <div class="commentTitle">
                                        <div class="leftBox">
                                            <p class="name">닉네임</p>
                                            <p class="date">21-02-17 14:57</p>
                                        </div>
                                        <div class="btnWrap">
                                            <button type="button" class="menuBtn">
                                                <img src="./img/boardImg/threedot.png" alt="메뉴" class="icon"/>
                                            </button>
                                            <ul class="subMenu">
                                                <li><button type="button" class="item">수정</button></li>
                                                <li><button type="button" class="item">답변</button></li>
                                                <li><button type="button" class="item">삭제</button></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="commentText">
                                        진정한 의미에서의 디자인이란 세상을 바꾸고자 그것을 바라보는 어떤 방식을 말한다.<br/>
                                        디자이너가 세상을 바꾸려 한다면, 지금의 세상은 물론, 될 법한 세상의 모습도 볼 줄 알아야 한다.<br/>
                                        그들은 스케치하거나 모형을 만들어 아이디어에 구체적인 형태를 부여한다.<br/>
                                        디자이너란 아주 희박한 일말의 가능성을 포착하여 그것을 시각화하고, 다른 사람들에게는 <br/>
                                        그것이 현실이 되도록 만드는 사람들이다. 좋은디자인이란 대체할수 없는 가치를 가지는것 이다.
                                    </div>
                                </div>
                                <div class="commentBox">
                                    <div class="commentTitle">
                                        <div class="leftBox">
                                            <p class="name">닉네임</p>
                                            <p class="date">21-02-17 14:57</p>
                                        </div>
                                        <div class="btnWrap">
                                            <button type="button" class="menuBtn">
                                                <img src="./img/boardImg/threedot.png" alt="메뉴" class="icon"/>
                                            </button>
                                            <ul class="subMenu">
                                                <li><button type="button" class="item">수정</button></li>
                                                <li><button type="button" class="item">답변</button></li>
                                                <li><button type="button" class="item">삭제</button></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="commentText">
                                        진정한 의미에서의 디자인이란 세상을 바꾸고자 그것을 바라보는 어떤 방식을 말한다.<br/>
                                        디자이너가 세상을 바꾸려 한다면, 지금의 세상은 물론, 될 법한 세상의 모습도 볼 줄 알아야 한다.<br/>
                                        그들은 스케치하거나 모형을 만들어 아이디어에 구체적인 형태를 부여한다.<br/>
                                        디자이너란 아주 희박한 일말의 가능성을 포착하여 그것을 시각화하고, 다른 사람들에게는 <br/>
                                        그것이 현실이 되도록 만드는 사람들이다. 좋은디자인이란 대체할수 없는 가치를 가지는것 이다.
                                    </div>
                                </div>
                                <div class="commentBox">
                                    <div class="commentTitle">
                                        <div class="leftBox">
                                            <p class="name">닉네임</p>
                                            <p class="date">21-02-17 14:57</p>
                                        </div>
                                        <div class="btnWrap">
                                            <button type="button" class="menuBtn">
                                                <img src="./img/boardImg/threedot.png" alt="메뉴" class="icon"/>
                                            </button>
                                            <ul class="subMenu">
                                                <li><button type="button" class="item">수정</button></li>
                                                <li><button type="button" class="item">답변</button></li>
                                                <li><button type="button" class="item">삭제</button></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="commentText">
                                        진정한 의미에서의 디자인이란 세상을 바꾸고자 그것을 바라보는 어떤 방식을 말한다.<br/>
                                        디자이너가 세상을 바꾸려 한다면, 지금의 세상은 물론, 될 법한 세상의 모습도 볼 줄 알아야 한다.<br/>
                                        그들은 스케치하거나 모형을 만들어 아이디어에 구체적인 형태를 부여한다.<br/>
                                        디자이너란 아주 희박한 일말의 가능성을 포착하여 그것을 시각화하고, 다른 사람들에게는 <br/>
                                        그것이 현실이 되도록 만드는 사람들이다. 좋은디자인이란 대체할수 없는 가치를 가지는것 이다.
                                    </div>
                                </div>
                                <div class="commentBox reply">
                                    <div class="commentTitle">
                                        <div class="leftBox">
                                            <img src="./img/boardImg/replyIcon.png" alt="답변" />
                                            <p class="name">닉네임</p>
                                            <p class="date">21-02-17 14:57</p>
                                        </div>
                                        <div class="btnWrap">
                                            <button type="button" class="menuBtn">
                                                <img src="./img/boardImg/threedot.png" alt="메뉴" class="icon"/>
                                            </button>
                                            <ul class="subMenu">
                                                <li><button type="button" class="item">수정</button></li>
                                                <li><button type="button" class="item">답변</button></li>
                                                <li><button type="button" class="item">삭제</button></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="commentText">
                                        진정한 의미에서의 디자인이란 세상을 바꾸고자 그것을 바라보는 어떤 방식을 말한다.<br/>
                                        디자이너가 세상을 바꾸려 한다면, 지금의 세상은 물론, 될 법한 세상의 모습도 볼 줄 알아야 한다.<br/>
                                        그들은 스케치하거나 모형을 만들어 아이디어에 구체적인 형태를 부여한다.<br/>
                                        디자이너란 아주 희박한 일말의 가능성을 포착하여 그것을 시각화하고, 다른 사람들에게는 <br/>
                                        그것이 현실이 되도록 만드는 사람들이다. 좋은디자인이란 대체할수 없는 가치를 가지는것 이다.
                                    </div>
                                </div>
                                <div class="commentBox">
                                    <div class="commentTitle">
                                        <div class="leftBox">
                                            <p class="name">닉네임</p>
                                            <p class="date">21-02-17 14:57</p>
                                        </div>
                                        <div class="btnWrap">
                                            <button type="button" class="menuBtn">
                                                <img src="./img/boardImg/threedot.png" alt="메뉴" class="icon"/>
                                            </button>
                                            <ul class="subMenu">
                                                <li><button type="button" class="item">수정</button></li>
                                                <li><button type="button" class="item">답변</button></li>
                                                <li><button type="button" class="item">삭제</button></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="commentText">
                                        진정한 의미에서의 디자인이란 세상을 바꾸고자 그것을 바라보는 어떤 방식을 말한다.<br/>
                                        디자이너가 세상을 바꾸려 한다면, 지금의 세상은 물론, 될 법한 세상의 모습도 볼 줄 알아야 한다.<br/>
                                        그들은 스케치하거나 모형을 만들어 아이디어에 구체적인 형태를 부여한다.<br/>
                                        디자이너란 아주 희박한 일말의 가능성을 포착하여 그것을 시각화하고, 다른 사람들에게는 <br/>
                                        그것이 현실이 되도록 만드는 사람들이다. 좋은디자인이란 대체할수 없는 가치를 가지는것 이다.
                                    </div>
                                </div>
                            </div>
                            <div class="commentWriteBox">
                                <textarea class="commentWrite" placeholder="댓글 내용을 입력해주세요."></textarea>
                                <div class="commentInfo">
                                    <input type="text" name="" placeholder="이름" class="ipt" required/>
                                    <input type="password" name="" placeholder="비밀번호" class="ipt" required/>
                                </div>
                                <div class="autoBox">자동등록방지 박스</div>
                                <div class="btnWrap">
                                    <button type="button" class="submitBtn">댓글등록</button>
                                </div>
                            </div>
                        </article> */}

                    </div>
                </section>


            
            </ListWrap>
            </ContentArticle>
        </div>
    )
}

const ListWrap = styled.article`
    margin-top:40px;
`

const ContentArticle = styled.article`
    .inner{ width: 100%; padding:0 10px; }
    .boardArti{ padding:50px 0 100px 0; }
    // padding:100px 0; 

    @media screen and (max-width: 650px) {
        .boardArti{ padding:20px 0 100px 0; }
    }
`
const BottomBarTitle = styled.article`
    display:flex; align-items:center; gap:10px; border-bottom:4px solid #222; padding-bottom:13px; margin-bottom:10px;
    .title{ font-size:26px; font-weight:700; }

    @media screen and (max-width: 1250px) {
        gap:5px;
        .title{ font-size:20px; }
        .logo{ width:30px; }
    }
    @media screen and (max-width: 650px) {
        border-bottom:2px solid #222; 
        .title{ font-size:18px; }
        .logo{ width:25px; }
    }
`

const BannerArti = styled.article `
    padding: 200px 0 100px 0; background-size: cover; background-repeat: no-repeat; background-position: center;
    background-image : ${ (
    props
) => props.bgImage
    ? `url(${props.bgImage})`
    : `url(${process.env.PUBLIC_URL}/images/example_img.png)`};
    color:#FFF; 

    .title{ font-size:56px; font-weight:700; }
    .content{ font-size:18px; margin-top:20px; }
    .inner{ display:flex; justify-content: space-between; gap:20px; flex-wrap:wrap; }
    .contentBox{ line-height:1.5em; font-size:26px; font-weight:700; }


    @media screen and (max-width: 1250px) {
        .title{ font-size:40px; }
        .content{ font-size:16px; }
        .contentBox{ width:100%; font-size:18px; }
    }
    @media screen and (max-width: 650px) {
        .title{ font-size:35px; }
        .content{ font-size:14px; margin-top:10px; }
        .contentBox{ font-size:16px; }
    }
`;

export default PaymentView;
