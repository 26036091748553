import { useEffect, useRef } from 'react';
import { Link, useLocation,useNavigate } from 'react-router-dom';
import "./Header.css"
import axios from 'axios';



const Header = ({setMenuStatus , menuStatus, setPrivacyPopup, privacyPopup, user, setUser,setUserdata,setAlarmData}) => { 
    /* 
        menuStatus는 현재 메뉴 active상태  ( true이면 보이고, false이면 안보이게!! )
    */






    const navigate = useNavigate();

    const {pathname} = useLocation();

    const MenuHandler = () => { setMenuStatus(!menuStatus); } //메뉴 열기
    const LogoHandler = () => { 
        setMenuStatus( false ); 
        window.scrollTo({
            top: 0,
            left: 0, 
            behavior: 'smooth'
        }); 
    } //메뉴 닫기

    const privacyPopupHandler = (e) => {
        e.preventDefault();
        setPrivacyPopup(!privacyPopup);
    }
    
    const logoutRequestHandler = async (e) => {
        e.preventDefault();
        // try {
    //     if (window.Kakao.Auth.getAccessToken()) {
    //     window.Kakao.API.request({
    //     url: '/v1/user/unlink',
    //     success: function (response) {
    //     	console.log(response)
    //     },
    //     fail: function (error) {
    //       console.log(error)
    //     },
    //   })
    //   window.Kakao.Auth.setAccessToken(undefined)
    // }
            localStorage.clear();
            setUser(null);
            setUserdata(null);
            setAlarmData(null);
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/users/logout`,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    withCredentials: true
                }
            );
            console.log(response.data);
            if (response.data.message == "S") {
                alert('로그아웃 되었습니다.');
                const redirectURL = `/`;
                navigate(redirectURL);
            } else {
                alert('존재하지 않는 회원입니다.');
            }
        // } catch (error) {
        //     console.log(error);
        //     alert(error);
        // }
    };

    if(!pathname.startsWith('/mypage')){
        return(
           
            <>
                <header id="header_24">
                    <div className="inner">
                        <Link to="/">
                            <img src={`${process.env.PUBLIC_URL}/images/2024/common/logo.png`} alt="JOBOFBROTHERS" className="logo" />
                        </Link>
                        <button type="button" className={(menuStatus) ? "sideMenuBtn active" : "sideMenuBtn"} onClick={MenuHandler}>
                            <img src={`${process.env.PUBLIC_URL}/images/2024/common/menuBtn.png`} alt="메뉴" className="icon" />
                        </button>
                        <ul className="menuList">
                            <li><Link to="/about">회사소개</Link></li>
                            <li><Link to="/service">차별성</Link></li>
                            <li><Link to="/project">포트폴리오</Link></li>
                            <li><Link to="/column">개발기술</Link></li>
                            {/* <li><Link to="#">유지보수 게시판</Link></li> */}
                            {/* <li><Link to="#">개인 결제</Link></li> */}
                            {/* {user ? (
                                <>
                                    <li><Link to="/mypage">유지보수 게시판</Link></li>
                                </>
                            ) : (
                                <>
                                    <li><Link to="/login" user={user} onClick={MenuHandler}>유지보수 게시판</Link></li>
                                </>
                            )} */}
                            {/* <li><Link to="">개인결제</Link></li> */}
                            <li><Link to="/contact/write" className="blakBtn">문의하기</Link></li>
                        </ul>
                    </div>
                </header>

                <section className={(menuStatus) ? "menuCloseBg show" : "menuCloseBg"} onClick={MenuHandler}></section>
                <section className={(menuStatus) ? "rightMenuSection show" : "rightMenuSection"}>
                    <div className="closeBtnDiv">
                        <button type="button" className="closeBtn" onClick={MenuHandler}>
                            <img src={`${process.env.PUBLIC_URL}/images/2024/common/close-btn.png`} alt="닫기" className="icon" />
                        </button>
                    </div>
                    <ul className="menuList">
                        <li><Link to="/about" onClick={MenuHandler}>회사소개</Link></li>
                        <li><Link to="/service" onClick={MenuHandler}>차별성</Link></li>
                        <li><Link to="/project" onClick={MenuHandler}>포트폴리오</Link></li>
                        <li><Link to="/column" onClick={MenuHandler}>개발기술</Link></li>
                        {/* <li><Link to="#" onClick={MenuHandler}>유지보수 게시판</Link></li> */}
                        {/* <li><Link to="#" onClick={MenuHandler}>개인 결제</Link></li> */}
                        <li><Link to="/contact/write" onClick={MenuHandler}>문의하기</Link></li>
                    </ul>
                </section>
            </>
        );
    }
    
}


export default Header; 