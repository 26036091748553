import AdminMenu from 'components/AdminMenu';
import styled from 'styled-components';
import axios from 'axios';
import {useState, useEffect} from 'react';
import {Link, useParams,useNavigate} from 'react-router-dom';
import PagingBox from 'components/PagingBox';


function MaintenanceList() {

    return (
        <div className="rootConatiner">
            <BannerArti bgImage={`${process.env.PUBLIC_URL}/images/2024/maintenance/bannerBg.png`}>
                <div className='inner'>
                    <div className='textBox'>
                        <p className="title">Maintenance</p>
                    </div>
                    <div class="contentBox">
                        친절하고 자세하게<br/>
                        안내드리겠습니다
                    </div>
                </div>
            </BannerArti>
            <ContentArticle>
                
            <ListWrap>
                <article class="boardArti basicBoardArti">
                    <div class="inner">
                        <BottomBarTitle>
                            <img src={`${process.env.PUBLIC_URL}/images/2024/maintenance/logo.png`} alt="잡오브브라더스" className="logo"/>
                            <div className="title">유지보수 게시판</div>
                        </BottomBarTitle>
                        <AdminMenu boardName="maintenanceList" type="list" />
                        <ul class="basicList">
                            <li class="boardTitle">
                                <div class="item">
                                    <div class="chk center">
                                        <label class="allchk">
                                            <input type="checkbox" />
                                        </label>
                                    </div>
                                    <div class="number center">번호</div>
                                    <div class="title center">제목</div>
                                    <div class="writer center">글쓴이</div>
                                    <div class="hit center">조회수</div>
                                    <div class="date center">등록일</div>
                                </div>
                            </li>
                            <li>
                                <div class="item">
                                    <div class="chk center"><input type="checkbox" /></div>
                                    <div class="number center on">공지</div>
                                    <div class="title">
                                        <a href="./view.html">제목 영역입니다. 홈페이지가 오픈하였습니다. 제목 영역입니다. 홈페이지가 오픈하였습니다. 제목 영역입니다. 홈페이지가 오픈하였습니다. 제목 영역입니다. 홈페이지가 오픈하였습니다.</a>
                                    </div>
                                    <div class="writer center">최고관리자 최고관리자 최고관리자 최고관리자</div>
                                    <div class="hit center">9,9999,9999,999</div>
                                    <div class="date center">10-12-10-12 10-12-10-12</div>
                                </div>
                            </li>
                            <li>
                                <div class="item">
                                    <div class="chk center"><input type="checkbox" /></div>
                                    <div class="number center on">공지</div>
                                    <div class="title">
                                        <div class="iconWrap">
                                            <img src={`${process.env.PUBLIC_URL}/images/2024/boardImg/replyIcon.png`} alt="답변" class="icon"/>
                                        </div>
                                        <a href="./view.html">제목 영역입니다. 홈페이지가 오픈하였습니다. 제목 영역입니다. 홈페이지가 오픈하였습니다. 제목 영역입니다. 홈페이지가 오픈하였습니다. 제목 영역입니다. 홈페이지가 오픈하였습니다.</a>
                                    </div>
                                    <div class="writer center">최고관리자</div>
                                    <div class="hit center">9,999</div>
                                    <div class="date center">10-12</div>
                                </div>
                            </li>
                            <li>
                                <div class="item">
                                    <div class="chk center"><input type="checkbox" /></div>
                                    <div class="number center on">공지</div>
                                    <div class="title">
                                        <div class="iconWrap">
                                            <img src={`${process.env.PUBLIC_URL}/images/2024/boardImg/replyIcon.png`} alt="답변" class="icon"/>
                                            <img src={`${process.env.PUBLIC_URL}/images/2024/boardImg/secret.png`} alt="비밀" class="icon" />
                                        </div>
                                        <a href="./view.html">제목 영역입니다. 홈페이지가 오픈하였습니다. 제목 영역입니다. 홈페이지가 오픈하였습니다. 제목 영역입니다. 홈페이지가 오픈하였습니다. 제목 영역입니다. 홈페이지가 오픈하였습니다.</a>
                                    </div>
                                    <div class="writer center">최고관리자</div>
                                    <div class="hit center">9,999</div>
                                    <div class="date center">10-12</div>
                                </div>
                            </li>
                            <li>
                                <div class="item">
                                    <div class="chk center"><input type="checkbox" /></div>
                                    <div class="number center">10</div>
                                    <div class="title">
                                        <a href="./view.html">제목 영역입니다. 홈페이지가 오픈하였습니다. 제목 영역입니다. 홈페이지가 오픈하였습니다. 제목 영역입니다. 홈페이지가 오픈하였습니다. 제목 영역입니다. 홈페이지가 오픈하였습니다.</a>
                                        <div class="iconWrap">
                                            <img src={`${process.env.PUBLIC_URL}/images/2024/boardImg/heart.png`} alt="HOT" class="icon"/>
                                            <img src={`${process.env.PUBLIC_URL}/images/2024/boardImg/file.png`} alt="파일" class="icon" />
                                            <img src={`${process.env.PUBLIC_URL}/images/2024/boardImg/link.png`} alt="링크" class="icon" />
                                            <span class="reple">24</span>
                                        </div>
                                    </div>
                                    <div class="writer center">최고관리자</div>
                                    <div class="hit center">9,999</div>
                                    <div class="date center">10-12</div>
                                </div>
                            </li>
                            <li>
                                <div class="item">
                                    <div class="chk center"><input type="checkbox" /></div>
                                    <div class="number center">9</div>
                                    <div class="title"><a href="./view.html">제목 영역입니다. 홈페이지가 오픈하였습니다. 제목 영역입니다. 홈페이지가 오픈하였습니다. 제목 영역입니다. 홈페이지가 오픈하였습니다. 제목 영역입니다. 홈페이지가 오픈하였습니다.</a></div>
                                    <div class="writer center">최고관리자</div>
                                    <div class="hit center">9,999</div>
                                    <div class="date center">10-12</div>
                                </div>
                            </li>
                            <li>
                                <div class="item">
                                    <div class="chk center"><input type="checkbox" /></div>
                                    <div class="number center">8</div>
                                    <div class="title"><a href="./view.html">제목 영역입니다. 홈페이지가 오픈하였습니다. 제목 영역입니다. 홈페이지가 오픈하였습니다. 제목 영역입니다. 홈페이지가 오픈하였습니다. 제목 영역입니다. 홈페이지가 오픈하였습니다.</a></div>
                                    <div class="writer center">최고관리자</div>
                                    <div class="hit center">9,999</div>
                                    <div class="date center">10-12</div>
                                </div>
                            </li>
                            <li>
                                <div class="item">
                                    <div class="chk center"><input type="checkbox" /></div>
                                    <div class="number center">7</div>
                                    <div class="title"><a href="./view.html">제목 영역입니다. 홈페이지가 오픈하였습니다. 제목 영역입니다. 홈페이지가 오픈하였습니다. 제목 영역입니다. 홈페이지가 오픈하였습니다. 제목 영역입니다. 홈페이지가 오픈하였습니다.</a></div>
                                    <div class="writer center">최고관리자</div>
                                    <div class="hit center">9,999</div>
                                    <div class="date center">10-12</div>
                                </div>
                            </li>
                            <li>
                                <div class="item">
                                    <div class="chk center"><input type="checkbox" /></div>
                                    <div class="number center">6</div>
                                    <div class="title"><a href="./view.html">제목 영역입니다. 홈페이지가 오픈하였습니다. 제목 영역입니다. 홈페이지가 오픈하였습니다. 제목 영역입니다. 홈페이지가 오픈하였습니다. 제목 영역입니다. 홈페이지가 오픈하였습니다.</a></div>
                                    <div class="writer center">최고관리자</div>
                                    <div class="hit center">9,999</div>
                                    <div class="date center">10-12</div>
                                </div>
                            </li>
                            <li>
                                <div class="item">
                                    <div class="chk center"><input type="checkbox" /></div>
                                    <div class="number center">5</div>
                                    <div class="title"><a href="./view.html">제목 영역입니다. 홈페이지가 오픈하였습니다. 제목 영역입니다. 홈페이지가 오픈하였습니다. 제목 영역입니다. 홈페이지가 오픈하였습니다. 제목 영역입니다. 홈페이지가 오픈하였습니다.</a></div>
                                    <div class="writer center">최고관리자</div>
                                    <div class="hit center">9,999</div>
                                    <div class="date center">10-12</div>
                                </div>
                            </li>
                            <li>
                                <div class="item">
                                    <div class="chk center"><input type="checkbox" /></div>
                                    <div class="number center">4</div>
                                    <div class="title"><a href="./view.html">제목 영역입니다. 홈페이지가 오픈하였습니다. 제목 영역입니다. 홈페이지가 오픈하였습니다. 제목 영역입니다. 홈페이지가 오픈하였습니다. 제목 영역입니다. 홈페이지가 오픈하였습니다.</a></div>
                                    <div class="writer center">최고관리자</div>
                                    <div class="hit center">9,999</div>
                                    <div class="date center">10-12</div>
                                </div>
                            </li>
                            <li>
                                <div class="item">
                                    <div class="chk center"><input type="checkbox" /></div>
                                    <div class="number center">3</div>
                                    <div class="title"><a href="./view.html">제목 영역입니다. 홈페이지가 오픈하였습니다. 제목 영역입니다. 홈페이지가 오픈하였습니다. 제목 영역입니다. 홈페이지가 오픈하였습니다. 제목 영역입니다. 홈페이지가 오픈하였습니다.</a></div>
                                    <div class="writer center">최고관리자</div>
                                    <div class="hit center">9,999</div>
                                    <div class="date center">10-12</div>
                                </div>
                            </li>
                            <li>
                                <div class="item">
                                    <div class="chk center"><input type="checkbox" /></div>
                                    <div class="number center">2</div>
                                    <div class="title"><a href="./view.html">제목 영역입니다. 홈페이지가 오픈하였습니다. 제목 영역입니다. 홈페이지가 오픈하였습니다. 제목 영역입니다. 홈페이지가 오픈하였습니다. 제목 영역입니다. 홈페이지가 오픈하였습니다.</a></div>
                                    <div class="writer center">최고관리자</div>
                                    <div class="hit center">9,999</div>
                                    <div class="date center">10-12</div>
                                </div>
                            </li>
                            <li>
                                <div class="item">
                                    <div class="chk center"><input type="checkbox" /></div>
                                    <div class="number center">1</div>
                                    <div class="title"><a href="./view.html">제목 영역입니다. 홈페이지가 오픈하였습니다. 제목 영역입니다. 홈페이지가 오픈하였습니다. 제목 영역입니다. 홈페이지가 오픈하였습니다. 제목 영역입니다. 홈페이지가 오픈하였습니다.</a></div>
                                    <div class="writer center">최고관리자</div>
                                    <div class="hit center">9,999</div>
                                    <div class="date center">10-12</div>
                                </div>
                            </li>
                        </ul>

                        <div className="paging_box">페이징 위치입니다</div>
                    </div>
                </article>
            </ListWrap>
            </ContentArticle>
        </div>
    )
}

const ListWrap = styled.article`
    margin-top:40px;
`

const ContentArticle = styled.article`
    .inner{ width: 100%; padding:0 10px; }
    .boardArti{ padding:50px 0 100px 0; }
    // padding:100px 0; 

    @media screen and (max-width: 650px) {
        .boardArti{ padding:20px 0 100px 0; }
    }
`
const BottomBarTitle = styled.article`
    display:flex; align-items:center; gap:10px; border-bottom:4px solid #222; padding-bottom:13px;
    .title{ font-size:26px; font-weight:700; }

    @media screen and (max-width: 1250px) {
        gap:5px;
        .title{ font-size:20px; }
        .logo{ width:30px; }
    }
    @media screen and (max-width: 650px) {
        border-bottom:2px solid #222; 
        .title{ font-size:18px; }
        .logo{ width:25px; }
    }
`

const BannerArti = styled.article `
    padding: 200px 0 100px 0; background-size: cover; background-repeat: no-repeat; background-position: center;
    background-image : ${ (
    props
) => props.bgImage
    ? `url(${props.bgImage})`
    : `url(${process.env.PUBLIC_URL}/images/example_img.png)`};
    color:#FFF; 

    .title{ font-size:56px; font-weight:700; }
    .content{ font-size:18px; margin-top:20px; }
    .inner{ display:flex; justify-content: space-between; gap:20px; flex-wrap:wrap; }
    .contentBox{ line-height:1.5em; font-size:26px; font-weight:700; }


    @media screen and (max-width: 1250px) {
        .title{ font-size:40px; }
        .content{ font-size:16px; }
        .contentBox{ width:100%; font-size:18px; }
    }
    @media screen and (max-width: 650px) {
        .title{ font-size:35px; }
        .content{ font-size:14px; margin-top:10px; }
        .contentBox{ font-size:16px; }
    }
`;

export default MaintenanceList;
